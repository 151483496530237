function Contact() {
  return (
    <div className="py-12 bg-gray-100" id="contact">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Get in Touch
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Let's discuss your next project
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto">
          <iframe
            src="https://forms.gle/92TmoKQeCmxxifZg6"
            width="100%"
            height="1000px"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Contact Form"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
