function Navbar() {
  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex-shrink-0">
            <span className="text-2xl font-bold text-gray-800">CMYK718</span>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              <a
                href="#home"
                className="text-gray-800 hover:text-gray-600 px-3 py-2"
              >
                Home
              </a>
              <a
                href="#projects"
                className="text-gray-800 hover:text-gray-600 px-3 py-2"
              >
                Projects
              </a>
              <a
                href="#contact"
                className="text-gray-800 hover:text-gray-600 px-3 py-2"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
