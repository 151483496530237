function Projects() {
  return (
    <div className="py-12 bg-white" id="projects">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Recent Projects
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            A selection of my recent web design work
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {/* Project Card 1 */}
          <div className="bg-gray-100 rounded-lg overflow-hidden shadow-lg">
            <div className="h-48 bg-gray-200"></div>
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900">Project One</h3>
              <p className="mt-2 text-gray-500">
                E-commerce website with modern design
              </p>
            </div>
          </div>

          {/* Project Card 2 */}
          <div className="bg-gray-100 rounded-lg overflow-hidden shadow-lg">
            <div className="h-48 bg-gray-200"></div>
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900">Project Two</h3>
              <p className="mt-2 text-gray-500">
                Portfolio website for photographer
              </p>
            </div>
          </div>

          {/* Project Card 3 */}
          <div className="bg-gray-100 rounded-lg overflow-hidden shadow-lg">
            <div className="h-48 bg-gray-200"></div>
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900">
                Project Three
              </h3>
              <p className="mt-2 text-gray-500">Restaurant booking platform</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
